
import Vue from "vue";

export default Vue.extend<{}, {}, Computed, {}>({
  computed: {
    reasonQuery() {
      return this.$route.query.reason;
    },
    title() {
      switch (this.reasonQuery) {
        case "notlogin":
          return "共有された作品を見るにはNolaアカウントが必要です。\n無料アカウントを登録してご利用をお願いします。";
        case "disabled":
        default:
          return "この共有リンクは閲覧できません";
      }
    },
    description() {
      switch (this.reasonQuery) {
        case "notlogin":
          return "申し訳ございません。作品の共有機能のご利用にはNolaアカウントが必要となっております。つきましては、下記のボタンから新規会員登録を行い、再度共有リンクを開いていただけますと幸いです。\nお手数をお掛け致しますが、何卒よろしくお願いいたします。";
        case "disabled":
        default:
          return "この共有リンクは下記の理由により閲覧することができません。\n\n・PC以外で共有リンクを開いているため（※ 現状、作品共有機能はPC版Nolaのみでご利用いただけます。）\n・著者様により共有リンクが非表示設定にされている、または、削除されているため\n\n作品の閲覧をご希望の場合は上記内容をご確認の上、必要に応じて共有リンクの発行者様にご確認をお願いいたします。";
      }
    },
  },
});

interface Computed {
  reasonQuery: string;
  title: string;
  description: string;
}
